import mlcontour from "maplibre-contour";
import {useEffect, useMemo} from "react";
import maplibregl, {RasterDEMTileSource} from "maplibre-gl";
import {useMapContext} from "../contexts/MapContext";

export type ContourSourceProps = {
    demSource: string;
    beforeId: string;
    layersToRemove?: string[];
};

export const useContourSource = ({demSource, beforeId, layersToRemove}: ContourSourceProps) => {
    const {map} = useMapContext();
    const source = useMemo(() => {
        if (!map) {
            return undefined;
        }
        const rasterDem  = map.getSource(demSource) as RasterDEMTileSource | undefined;
        if (!rasterDem) {
            return undefined;
        }
        const src = new mlcontour.DemSource({
            id: "contourSource",
            maxzoom: rasterDem.maxzoom,
            encoding: "mapbox",
            worker: true,
            url: rasterDem.tiles[0]
        });
        src.setupMaplibre(maplibregl);
        return src;
    }, [demSource, map]);
    useEffect(() => {
        if (!map || !source) {
            return;
        }
        map.addSource("contourSource", {
            type: 'vector',
            tiles: [
                source.contourProtocolUrl({
                    multiplier: 1,
                    overzoom: 1,
                    thresholds: {
                        // zoom: [minor, major]
                        9: [100, 500],
                        10: [100, 500],
                        11: [100, 500],
                        12: [20, 100],
                        13: [20, 100],
                        14: [20, 100],
                        15: [20, 100]
                    },
                    elevationKey: 'ele',
                    levelKey: 'level',
                    contourLayer: 'demContours'
                })
            ],
            maxzoom: 18
        });
        map.addLayer(
            {
                id: 'contour-text',
                type: 'symbol',
                minzoom: 11,
                source: 'contourSource',
                'source-layer': 'demContours',
                filter: ["all",
                    ['>', ['get', 'level'], 0],
                    ['>', ['get', 'ele'], 0]
                ],
                paint: {
                    "text-halo-blur": 0.5,
                    "text-color": "rgba(193, 104, 9, 1)",
                    "text-halo-color": "rgba(243, 243, 242, 1)",
                    "text-halo-width": 1
                },
                layout: {
                    'symbol-placement': 'line',
                    'text-size': 10,
                    'text-field': ['number-format', ['get', 'ele'], {}],
                    'text-font': ['Noto Sans Bold']
                }
            }, beforeId);
        map.addLayer({
            id: 'contours',
            type: 'line',
            minzoom: 9,
            source: 'contourSource',
            'source-layer': 'demContours',
            filter: [">", ["get", "ele"], 0],
            paint: {
                "line-color": "rgba(206, 103, 6, 0.75)",
                // "major" contours have level=1, "minor" have level=0
                'line-width': ['match', ['get', 'level'], 1, 1, 0.5]
            }
        }, "contour-text");
        layersToRemove?.forEach((layer) => map.getLayer(layer) != null && map.removeLayer(layer));
        return () => {
            map.removeLayer("contour-text");
            map.removeLayer("contours");
            map.removeSource("contourSource");
        }
    }, [demSource, layersToRemove, map, beforeId, source]);
    return demSource;
};
