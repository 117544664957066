import {createContext, useContext} from 'react';
import maplibregl from "maplibre-gl";
import {MapboxOverlay} from "@deck.gl/mapbox";

export type MapContextType = {
    map?: maplibregl.Map;
    deckOverlay?: MapboxOverlay;
}

export const MapContext = createContext<MapContextType>({});

export const useMapContext = () => useContext(MapContext);
