import {FC, useState} from "react";
import {NzOutdoors} from "./NzOutdoors";
import {LinzTopo} from "./LinzTopo";
import {LocationListener} from "../../maplibre/components/LocationListener";
import {JumpToOptions} from "maplibre-gl";
import {LocationUpdater} from "../../maplibre/components/LocationUpdater";

export const NzDiff: FC = () => {
    const [location, setLocation] = useState<JumpToOptions>();
    return (
       <div className="flex w-dvw h-dvh absolute p-0 m-0 gap-4">
           <NzOutdoors containerClassName="w-1/2 h-full p-0 m-0">
               <LocationListener onLocationChange={(loc) => setLocation(loc)}/>
           </NzOutdoors>
           <LinzTopo containerClassName="w-1/2 h-full p-0 m-0">
                <LocationUpdater jumpTo={location}/>
           </LinzTopo>
       </div>
    );
};
