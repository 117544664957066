import {FC, useEffect} from "react";
import {useMapContext} from "../contexts/MapContext";
import {GeolocateControl} from 'maplibre-gl';

export const GeolocateControlWidget: FC<{}> = () => {
    const {map} = useMapContext();
    useEffect(() => {
        if (!map) {
            return;
        }
        const control = new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
            },
            trackUserLocation: true,
        });
        map.addControl(control);
        return () => {
            map.removeControl(control);
        };
    }, [map]);
    return null;
};
