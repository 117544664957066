import {FC, useEffect} from "react";
import {useMapContext} from "../contexts/MapContext";
import {JumpToOptions} from 'maplibre-gl';

export type LocationUpdaterProps = {
    jumpTo?: JumpToOptions;
}

export const LocationUpdater: FC<LocationUpdaterProps> = ({ jumpTo }) => {
    const { map } = useMapContext();
    useEffect(() => {
        if (!map || !jumpTo) {
            return;
        }
        map.jumpTo(jumpTo);
    }, [map, jumpTo]);
    return null;
};
