import {FC, ReactNode} from 'react';
import {NZ_STYLE} from "./style";
import {MapContainer, UseMapOptions} from "../../maplibre/components/MapContainer";
import {ScaleControlWidget} from "../../maplibre/components/ScaleControlWidget";
import {Contours} from "../../maplibre/components/Contours";
import {GeolocateControlWidget} from "../../maplibre/components/GeolocateControlWidget";
import {LineLayerSpecification, SourceSpecification} from "maplibre-gl";
import {MapLayer, MapLayerSpecification} from "../../maplibre/components/MapLayer";
import {GeoJsonLayer} from "@deck.gl/layers";
import {TerrainLayer} from "@deck.gl/geo-layers";
import {_TerrainExtension as TerrainExtension} from '@deck.gl/extensions';
import {getIcons, getLayers, ruleAnalysis} from "../../util/layerUtil";

const nzStyle = NZ_STYLE;

console.log(`layers`, JSON.stringify(getLayers(nzStyle, "LINZ Basemaps"), null, 2));
console.log(`icons`, JSON.stringify(getIcons(nzStyle), null, 2));
console.log(`ruleAnalysis`, JSON.stringify(ruleAnalysis(nzStyle), null, 2));

// nzStyle.sky = {
//     "atmosphere-blend": ["interpolate", ["linear"], ["zoom"], 0, 1, 10, 1, 12, 0],
//     "fog-color": "#e8e8e8",
//     "fog-ground-blend": 0.8,
//     "horizon-color": "#ecffff",
//     "horizon-fog-blend": 0.65,
//     "sky-color": "#77b5fe",
//     "sky-horizon-blend": 0.5
// };

// Try once https://github.com/maplibre/maplibre-gl-js/pull/4300 is merged -
// NaN error when moving from inside terrain to outside terrain

const mapOptions: Omit<UseMapOptions, "container"> = {
    style: nzStyle,
    center: [172.5517563, -41.722541] as [number, number],
    zoom: 5,
    antialias: true,
    maplibreLogo: false,
    maxPitch: 0,
    disableRotation: true
};

const tracksSource: SourceSpecification = {
    type: 'geojson',
    data: '/docWalkingTracks.json'
}

const terrainDeck = new TerrainLayer({
    minZoom: 0,
    maxZoom: 22,
    elevationDecoder: {
        rScaler: 6553.6,
        gScaler: 25.6,
        bScaler: 0.1,
        offset: -10000
    },
    // Digital elevation model from https://www.usgs.gov/
    elevationData: 'https://basemaps.linz.govt.nz/v1/tiles/elevation/WebMercatorQuad/{z}/{x}/{y}.png?api=c01j1xq1ndwtrwz6y0mj1en4cdg&pipeline=terrain-rgb',
    tileSize: 256,
    operation: "terrain"
});

const tracksDeck = new GeoJsonLayer({
    data: "/docWalkingTracks.json",
    getFillColor: [160, 160, 180, 200],
    getLineColor: [0,0,0],
    getLineWidth: 20,
    getPointRadius: 4,
    getTextSize: 12,
    extensions: [new TerrainExtension()]
});

const tracksLayers: MapLayerSpecification<LineLayerSpecification>[] = [{
        'id': 'route',
        'type': 'line',
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': '#00F',
            'line-width': 8
        }
},{
    'id': 'routeHighlighted',
    'type': 'line',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': '#0FF',
        'line-width': 8
    },
    "filter": ["in", ["id"], '']
}];

// TODO: deal with natural contours properly
const linzContourLayers: string[] = ["Contours-All"];

const deckLayers = [terrainDeck, tracksDeck];

export type NzOutdoorsProps = {
    containerClassName?: string;
    children?: ReactNode;
};

export const NzOutdoors: FC<NzOutdoorsProps> = ({ containerClassName, children }) => {
    return (
        <MapContainer options={mapOptions} containerClassName={containerClassName}>
            <ScaleControlWidget />
            <Contours demSource="LINZ-Terrain" beforeId="Contours-Designated" layersToRemove={linzContourLayers}/>
            <GeolocateControlWidget />
            {/*<ElevationProfile />*/}
            <MapLayer source={tracksSource} layers={tracksLayers}/>
            {/*<DeckLayers layers={deckLayers}/>*/}
            {children}
        </MapContainer>
    );
};
