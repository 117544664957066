import {StyleSpecification} from "maplibre-gl";

export const getLayers = (style: StyleSpecification, source: string) => {
    const layerList: string[] = [];
    style.layers.forEach((layer) => {
        const layerSource = (layer as any)["source"];
        const sourceLayer = (layer as any)["source-layer"];
        if (source && sourceLayer) {
            if (layerSource === source) {
                layerList.push(sourceLayer);
            }
        }
    });
    return [...new Set(layerList)];
};

export const getIcons = (style: StyleSpecification) => {
    const iconList: string[] = [];
        style.layers.forEach((layer) => {
        const iconImage = (layer as any).layout?.["icon-image"];
        if (iconImage) {
            if (typeof iconImage === 'string') {
                iconList.push(iconImage);
            } else {
                // hopefully just stops
                // {
                //     "stops": [
                //     [
                //         12,
                //         "rail_station_pnt_red"
                //     ],
                //     [
                //         13,
                //         "rail_station_train_diesel_pnt_red"
                //     ]
                // ]
                // }
                const stops = (iconImage as any).stops as any[];
                stops.forEach((v) => iconList.push(v[1]));
            }
        }
    });
    return [...new Set(iconList)];
};

export const getLayersAndFields = (ogrInfo: any) => {
  const layers = ogrInfo.layers as any[];
  return layers.map((layer) => ({
      name: layer.name,
      featureCount: layer.featureCount,
      geometryFields: layer.geometryFields.map((gf: any) => gf.type),
      fields: (layer.fields as any[]).reduce((accum, f) => {
          accum[f.name] = f.type;
          return accum;
      }, {} as any)
  }));
};


export const ruleAnalysis = (style: StyleSpecification) => {
    const warnings: string[] = [];
    const sourceLayers: string[] = [];
    const noMinZoom: string[] = [];
    const noMaxZoom: string[] = [];
    style.layers.forEach((layer) => {
        const sourceLayer = (layer as any)["source-layer"];
        if (!sourceLayer) {
            warnings.push(`layer ${layer.id} has no source-layer`);
        } else {
            sourceLayers.push(sourceLayer);
        }
        const minZoom = layer.minzoom;
        const maxZoom = layer.maxzoom;
        if (minZoom == null) {
            noMinZoom.push(layer.id);
        }
        if (maxZoom == null) {
            noMaxZoom.push(layer.id);
        }
        switch (layer.type) {
            case "circle":
            case "fill":
            case "fill-extrusion":
            case "symbol":
            case "line":
            case "background":
            case "heatmap":
            case "raster":
            case "hillshade":
                break;
        }
    });
    warnings.push(`Source layers used: ${[sourceLayers.sort()]}`);
    warnings.push(`Layers without minzoom: ${noMinZoom}`);
    warnings.push(`Layers without maxzoom: ${noMaxZoom}`);
    return warnings;
};
