import {FC, ReactNode, useEffect, useMemo, useRef, useState} from "react";
import {MapOptions} from "maplibre-gl";
import {MapContext} from "../contexts/MapContext";
import {MapboxOverlay} from "@deck.gl/mapbox";
import {Map, IControl} from "maplibre-gl";

export type DeckOverlayProps = {
    interleaved: boolean;
};

const useDeckOverlay = (map: Map | undefined, options: DeckOverlayProps) => {
    const deckOverlay = useMemo(() => new MapboxOverlay(options), [options]);
    useEffect(() => {
        if (!map) {
            return;
        }
        map.addControl(deckOverlay as IControl);
        return () => {
            map.removeControl(deckOverlay as IControl);
        }
    }, [map, deckOverlay]);

    return deckOverlay;
};

export type UseMapOptions = Omit<MapOptions, "attributionControl"> & { disableRotation?: boolean; };

const useMap = (options: UseMapOptions): maplibregl.Map | undefined => {
    const [map, setMap] = useState<maplibregl.Map | undefined>();
    useEffect(() => {
        if (!options.container) {
            return;
        }
        const {disableRotation, ...normalOptions} = options;
        const m = new Map({
            ...normalOptions, attributionControl: {
                customAttribution: []
            }
        });
        let loading = true;
        m.on('error', (e) => console.log("Error", e));
        m.once('load', () => {
            if (loading) {
                if (disableRotation) {
                    // disable map rotation using right click + drag
                    m.dragRotate.disable();
                    // disable map rotation using keyboard
                    m.keyboard.disable();
                    // disable map rotation using touch rotation gesture
                    m.touchZoomRotate.disableRotation();
                }
                setMap(m);
                loading = false;
            }
        });
        m.on('zoomend', (e) => {
            console.log("zoom", m.getZoom());
        });
        return () => {
            loading = false;
            m?.remove();
            setMap(undefined);
        };
    }, [options]);
    return map;
};

export const MapContainer: FC<{
    options: Omit<UseMapOptions, "container">;
    containerClassName?: string;
    deckOverlayOptions?: DeckOverlayProps;
    children?: ReactNode;
}> = ({options, containerClassName, children}) => {
    const divRef = useRef<HTMLDivElement>(null);
    const [divElement, setDivElement] = useState<HTMLDivElement | null>(null);
    useEffect(() => setDivElement(divRef.current!), []);

    const mapOptions: MapOptions = useMemo(() => ({
        container: divElement ?? "",
        ...options
    }), [divElement, options]);
    return (
        <>
            <div
                ref={divRef}
                className={`${containerClassName ?? "h-dvh w-dvw p-0 m-0 absolute"}`}>
            </div>
            <InternalMap options={mapOptions}>
                {children ? <>{children}</> : null}
            </InternalMap>
        </>);
};

const DEFAULT_DECK_OVERLAY_OPTIONS = {
    interleaved: false
};

const InternalMap: FC<{ options: MapOptions, deckOverlayOptions?: DeckOverlayProps, children?: ReactNode }> = ({
                                                                                                                   options,
                                                                                                                   children,
                                                                                                                   deckOverlayOptions
                                                                                                               }) => {
    const map = useMap(options);
    const deckOverlay = useDeckOverlay(map, deckOverlayOptions ?? DEFAULT_DECK_OVERLAY_OPTIONS);
    if (!children) {
        return null;
    }
    return <MapContext.Provider value={{map, deckOverlay}}>{children}</MapContext.Provider>;
};
