import {FC, useEffect} from "react";
import {useMapContext} from "../contexts/MapContext";
import {JumpToOptions} from 'maplibre-gl';

export type LocationListenerProps = {
    onLocationChange?: (jumpTo: JumpToOptions) => void;
}

const CAMERA_EVENTS = ['movestart', 'move', 'moveend', 'zoomstart', 'zoom', 'zoomend', 'pitchstart', 'pitch', 'pitchend', 'rotate'];

export const LocationListener: FC<LocationListenerProps> = ({ onLocationChange }) => {
    const { map } = useMapContext();
    useEffect(() => {
        if (!map || !onLocationChange) {
            return;
        }
        const jumpTo = (): JumpToOptions => ({
            center: map.getCenter(),
            zoom: map.getZoom(),
            around: map.getCenter(),
            bearing: map.getBearing(),
            pitch: map.getPitch(),
            padding: map.getPadding()
        });
        const listenerFunc = () => onLocationChange(jumpTo());
        CAMERA_EVENTS.forEach((ce) => map.on(ce, listenerFunc));
        return () => {
            CAMERA_EVENTS.forEach((ce) => map.off(ce, listenerFunc));
        }
    }, [map, onLocationChange]);
    return null;
};
