import {FC, ReactNode, useEffect, useMemo, useRef} from "react";
import {useMapContext} from "../contexts/MapContext";
import {LayerSpecification} from "maplibre-gl";
import {MapLayerContext, MapLayerContextProps} from "../contexts/MapLayerContext";

export type MapLayerSpecification<X extends LayerSpecification> = Omit<X, "source">;

export type MapLayerProps = MapLayerContextProps & {
    children?: ReactNode;
};

export const MapLayer: FC<MapLayerProps> = ({ source, layers, children }) => {
    const contextProps = useMemo(() => ({ source, layers }), [source, layers]);
    const { map } = useMapContext();
    const sourceId = useRef<string>(crypto.randomUUID());
    useEffect(() => {
        if (!map) {
            return;
        }
        const sId = sourceId.current;
        map.addSource(sId, source);
        layers.forEach((layer, idx) => {
           map.addLayer({...layer, id: sId + "-" + idx, source: sId } as LayerSpecification);
        });
        return () => {
            layers.forEach((layer, idx) => {
              map.removeLayer(sId + "-" + idx);
            });
            map.removeSource(sId)
        }
    }, [source, layers, map]);
    if (!children) {
        return null;
    }
    return <MapLayerContext.Provider value={contextProps}>{children}</MapLayerContext.Provider>;
};
