import {createContext, useContext} from 'react';
import {SourceSpecification} from "maplibre-gl";
import {MapLayerSpecification} from "../components/MapLayer";

export type MapLayerContextProps = {
    source: SourceSpecification;
    layers: MapLayerSpecification<any>[];
};
export const MapLayerContext = createContext<MapLayerContextProps>(null!);

export const useMapLayerContext = () => useContext(MapLayerContext);
