import {FC, ReactNode} from 'react';
import {MapContainer, UseMapOptions} from "../../maplibre/components/MapContainer";
import {ScaleControlWidget} from "../../maplibre/components/ScaleControlWidget";
import {GeolocateControlWidget} from "../../maplibre/components/GeolocateControlWidget";

const mapOptions: Omit<UseMapOptions, "container"> = {
    style: "https://basemaps.linz.govt.nz/v1/styles/topographic.json?api=c01j1xq1ndwtrwz6y0mj1en4cdg",
    center: [172.5517563, -41.722541] as [number, number],
    zoom: 5,
    antialias: true,
    maplibreLogo: false,
    maxPitch: 0,
    disableRotation: true,
    interactive: false
};

export type LinzTopoProps = {
    containerClassName?: string;
    children?: ReactNode;
};

export const LinzTopo: FC<LinzTopoProps> = ({ containerClassName, children }) => {
    return (
        <MapContainer options={mapOptions} containerClassName={containerClassName}>
            <ScaleControlWidget />
            <GeolocateControlWidget />
            {children}
        </MapContainer>
    );
};
