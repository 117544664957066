import {FC, useEffect} from "react";
import {useMapContext} from "../contexts/MapContext";
import {
    ScaleControl
} from 'maplibre-gl';

const scaleProgression: Record<NonNullable<ScaleControl["options"]["unit"]>, NonNullable<ScaleControl["options"]["unit"]>> = {
    "metric": "imperial",
    "imperial": "nautical",
    "nautical": "metric"
};

export type ScaleControlWidgetProps = {
    adjustable?: boolean;
    unit?: ScaleControl["options"]["unit"];
}

export const ScaleControlWidget: FC<ScaleControlWidgetProps> = ({ adjustable, unit }) => {
    const { map } = useMapContext();
    useEffect(() => {
        if (!map) {
            return;
        }
        const scaleControl = new ScaleControl({
            maxWidth: 200,
            unit
        });
        map.addControl(
            scaleControl,
            'bottom-left'
        );
        if (adjustable) {
            scaleControl._container.onclick = () => {
                const unit = scaleControl.options.unit || "metric";
                scaleControl.setUnit(scaleProgression[unit]);
            };

        }
        return () => {
            if (adjustable) {
                scaleControl._container.onclick = null;
            }
            map.removeControl(scaleControl);
        }
    }, [map, adjustable, unit]);
    return null;
};
