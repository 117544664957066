import './App.css';
import {NzDiff} from "./maps/nz/NzDiff";

function App() {
  // useCorsProtocol();
  // return <Map/>;
  return <NzDiff/>;
  // return <NzOutdoors/>;
  // return <NzOutdoorsDeck />;
}

export default App;
